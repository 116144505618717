import { render, staticRenderFns } from "./NavBar.vue?vue&type=template&id=5d522b96&scoped=true&"
import script from "./NavBar.vue?vue&type=script&lang=js&"
export * from "./NavBar.vue?vue&type=script&lang=js&"
import style0 from "./NavBar.vue?vue&type=style&index=0&id=5d522b96&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.10.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d522b96",
  null
  
)

export default component.exports